<template>
    <div class="profile-card">
        <img :src="avatarUrl" alt="User Avatar" class="avatar" />
        <h2>{{ nickname }}</h2>
    </div>
</template>

<script>
export default {
    name: 'ProfileCard',
    props: {
        avatarUrl: {
            type: String,
            required: true,
        },
        nickname: {
            type: String,
            required: true,
        },
    },
    methods: {
    },
};
</script>

<style scoped>
.profile-card {
    padding: 0px;
    margin-bottom: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
    width: 200px;
    height: 200px;
    background-color: #fff;
}

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 5px;
}
</style>