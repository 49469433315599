import axios from "@/api/request";

// 账号密码登录
export function getLogin(data) {
  return axios({
    url: "/user/login",
    method: "post",
    data,
  });
}
// 手机号登录
export function getSmsLogin(data) {
  return axios({
    url: "/user/smsLogin",
    method: "get",
    params: data,
  });
}
// 获取验证码
export function getQRCode() {
  return axios({
    url: "/user/wechat/loginCaptcha",
    method: "get",
  });
}
// 检查微信验证码是否登录
export function checkLoginStatus(data) {
  return axios({
    url: "/user/wechat/checkLogin",
    method: "get",
    params: data,
  });
}
//登出
export function loginOut() {
  return axios({
    url: "/user/logout",
    method: "get",
  });
}