import Vue from 'vue'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import index  from '../views/index.vue';
import Login from '../views/Login.vue'
import Temp from '../views/temp.vue'

Vue.use(VueRouter)
Vue.use(ElementUI);
function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

const routes = [
  {
    path: '/',
    name: '系统界面',
    component: index,
    redirect: '/WeeklyReport', // 添加重定向
    children: [
      {
        path: '/WeeklyReport',
        name: '周报管理',
        // component: WeeklyReport,
        component: isMobile() ? () => import('@/views/WeeklyReport/WeeklyReportM.vue') : () => import('@/views/WeeklyReport/WeeklyReport.vue'),
      },
      {
        path: '/MessageBroker',
        name: '消息记录',
        // component: MessageBroker,
        component: isMobile() ? () => import('@/views/MessageBroker/MessageBrokerM.vue') : () => import('@/views/MessageBroker/MessageBroker.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: '登录界面',
    component: Login,
  },
  {
    path: '/temp',
    name: '测试界面',
    component: Temp,
  },
]

const router = new VueRouter({
  routes
})



// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}

export default router