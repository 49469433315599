import axios from "axios";
// 使用element-ui Message做消息提醒
import { Message } from "element-ui";
import {baseURL} from "@/utils/index";
import router from "@/router/index";

axios.defaults.baseURL = baseURL

// 请求拦截器
axios.interceptors.request.use(
  (request) => {
    if (localStorage.getItem("token")) {
      request.headers.token = localStorage.getItem("token");
    }
    
    return request;
  },
  (error) => Promise.reject(error)
);

// 响应连接器
axios.interceptors.response.use(
  (response) => {
    // console.log(response);
    if (response.data.code && response.data.code === 200) {
      return response.data
    } else {
      return response.data;
    }
  },
  (error) => {
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      switch (error.response.status) {
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          window.localStorage.removeItem("token")
          error.message = "该用户身份失效，请重新登录";
          router.push({ path: "/login" });
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = "请求错误,未找到该资源";
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器端出错";
          break;
        case 501:
          error.message = "网络未实现";
          break;
        case 502:
          error.message = "网络错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网络超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        default:
          error.message = `连接错误${error.response.code}`;
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        error.message = "服务器响应超时，请刷新当前页";
      }
      error.message = "连接服务器失败";
    }
    Message.error(error.message);
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.reject(error); 
  }
);

export default axios;
