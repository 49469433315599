<!-- MessageList.vue -->
<template>
    <div class="message-list-container">
      <div
        v-for="(message, index) in messages"
        :key="index"
        class="message-card-container"
        @touchstart="handleTouchStart($event, index)"
        @touchmove="handleTouchMove($event, index)"
        @touchend="handleTouchEnd($event, index)"
      >
        <div class="message-card" :style="{ transform: cardTransforms[index] }">
          <div class="card-content">
            <p>{{ message.time }}</p>
            <p>{{ message.content }}</p>
          </div>
        </div>
        <div class="action-buttons" :style="{ visibility: deleteButtonVisibility[index] }">
          <button class="delete-button" @click="deleteMessage(index)">删除</button>
          <button class="edit-button" @click="editMessage(index)">编辑</button>
        </div>
      </div>
  
      <div v-if="showEditDialog" class="edit-dialog" @click="closeEditDialog">
        <div class="edit-dialog-content" @click.stop>
          <h3>编辑内容</h3>
          <textarea v-model="currentMessageContent"></textarea>
          <button @click="confirmEdit">确认</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        messages: [], // 你的消息列表数据
        startX: 0,
        cardTransforms: [], // 存储每个卡片的 transform 样式
        deleteButtonVisibility: [], // 存储每个删除按钮的 visibility 样式
        showEditDialog: false,
        currentMessageContent: '',
        currentMessageIndex: null,
      };
    },
    methods: {
      handleTouchStart(event, index) {
        this.startX = event.touches[0].clientX;
      },
      handleTouchMove(event, index) {
        const touch = event.touches[0];
        const change = this.startX - touch.clientX;
  
        if (change > 0) {
          this.cardTransforms.splice(index, 1, `translateX(${-change}px)`);
          if (change > 100) {
            this.deleteButtonVisibility.splice(index, 1, 'visible');
          } else {
            this.deleteButtonVisibility.splice(index, 1, 'hidden');
          }
        }
      },
      handleTouchEnd(event, index) {
        const touch = event.changedTouches[0];
        const change = this.startX - touch.clientX;
  
        if (change > 100) { // 滑动超过一定距离，保持卡片位置，显示删除按钮
          this.cardTransforms.splice(index, 1, `translateX(-100px)`);
          this.deleteButtonVisibility.splice(index, 1, 'visible');
          // 隐藏其他所有卡片的删除按钮
          this.messages.forEach((_, i) => {
            if (i !== index) {
              this.cardTransforms.splice(i, 1, `translateX(0)`);
              this.deleteButtonVisibility.splice(i, 1, 'hidden');
            }
          });
        } else { // 滑动距离不够，复位
          this.cardTransforms.splice(index, 1, `translateX(0)`);
          this.deleteButtonVisibility.splice(index, 1, 'hidden');
        }
      },
      deleteMessage(index) {
        this.messages.splice(index, 1);
        this.cardTransforms.splice(index, 1);
        this.deleteButtonVisibility.splice(index, 1);
      },
      editMessage(index) {
        this.currentMessageContent = this.messages[index].content;
        this.currentMessageIndex = index;
        this.showEditDialog = true;
      },
      confirmEdit() {
        if (this.currentMessageIndex !== null) {
          this.messages[this.currentMessageIndex].content = this.currentMessageContent;
          this.showEditDialog = false;
          this.currentMessageIndex = null;
          this.currentMessageContent = '';
        }
      },
      closeEditDialog() {
        this.showEditDialog = false;
      },
    },
    mounted() {
      // 模拟从接口获取数据
      this.messages = [
        { time: '2024-07-20 10:00', content: '消息内容1' },
        { time: '2024-07-20 10:01', content: '消息内容2' },
        { time: '2024-07-20 10:02', content: '消息内容3' },
        { time: '2024-07-20 10:03', content: '消息内容4' },
        { time: '2024-07-20 10:04', content: '消息内容5' },
        { time: '2024-07-20 10:05', content: '消息内容6' },
        { time: '2024-07-20 10:06', content: '消息内容7' },
        { time: '2024-07-20 10:07', content: '消息内容8' },
        { time: '2024-07-20 10:08', content: '消息内容9' },
        { time: '2024-07-20 10:09', content: '消息内容10' },
        { time: '2024-07-20 10:10', content: '消息内容11' },
        { time: '2024-07-20 10:11', content: '消息内容12' },
      ];
  
      // 初始化卡片和删除按钮的样式
      this.messages.forEach(() => {
        this.cardTransforms.push('translateX(0)');
        this.deleteButtonVisibility.push('hidden');
      });
    },
  };
  </script>
  
  <style scoped>
  .message-list-container {
    height: 100vh; /* 设置为视口高度，可以根据需要调整 */
    overflow-y: auto; /* 允许垂直滚动 */
    padding: 10px; /* 适当的内边距 */
    box-sizing: border-box; /* 包含内边距和边框 */
  }
  
  .message-card-container {
    position: relative;
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .message-card {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 增加边框阴影 */
    border-radius: 15px; /* 增加圆角半径 */
    transition: transform 0.3s ease; /* 增加过渡效果 */
    flex: 1;
  }
  
  .card-content {
    z-index: 1;
  }
  
  .action-buttons {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column; /* 垂直排列 */
    align-items: center;
    justify-content: center;
    background-color: transparent;
    z-index: 0;
    visibility: hidden; /* 隐藏时设置为 hidden */
    transition: visibility 0.3s ease; /* 增加过渡效果 */
  }
  
  .delete-button,
  .edit-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px 0;
    cursor: pointer;
    border-radius: 15px; /* 增加圆角半径 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 增加边框阴影 */
    transition: background-color 0.3s ease;
  }
  
  .edit-button {
    background-color: orange;
  }
  
  .delete-button:hover,
  .edit-button:hover {
    background-color: darkred;
  }
  
  .edit-button:hover {
    background-color: darkorange;
  }
  
  .edit-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  
  .edit-dialog-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 400px;
  }
  
  .edit-dialog-content h3 {
    margin-top: 0;
  }
  
  .edit-dialog-content textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
  }
  
  .edit-dialog-content button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
  }
  
  .edit-dialog-content button:hover {
    background-color: #0056b3;
  }
  </style>
  